import React from 'react'
import logo from '../assets/logo.png'

const Footer = () => {
  return (
    <div className='footer w-full h-[160px] bg-[#131224] border-[#702fd2] border-solid border-t border-x rounded-t-[108px] flex flex-row flex-wrap items-center justify-center'>
      <img
        className='logo h-[72px] max-w-[90%] my-auto mx-0'
        src={logo}
        alt='Roundabout Technologies'
      />
      <a href='/contact' className='footer-link'>
        Contact Us
      </a>
      <a
        href='https://www.ycombinator.com/companies/roundabout-technologies/jobs'
        className='footer-link'
        target='_blank'
        rel='noopener noreferrer'
      >
        Careers
      </a>
    </div>
  )
}

export default Footer
