import React from 'react'
import logo from '../assets/logo.png'
import { motion } from 'framer-motion'

const Header = () => {
  return (
    <div className='header'>
      <motion.a
        href='/'
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.6, delay: 0.2 }}
      >
        <img src={logo} alt='Roundabout Technologies' className='logo' />
      </motion.a>
      <div style={{ flexGrow: 1 }}></div>
      <motion.a
        href='/team'
        style={{ marginRight: '50px', fontSize: '21px' }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.6, delay: 0.4 }}
      >
        Team
      </motion.a>
      <motion.a
        className='book-demo-btn'
        href='/contact'
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.6, delay: 0.6 }}
      >
        See a Demo
      </motion.a>
    </div>
  )
}

export default Header
