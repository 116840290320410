import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import demoVideo from '../assets/demo-video.mp4'
import speedDemo from '../assets/speed-demo.mp4'
import pedDemo from '../assets/ped-demo.mp4'
import waitTimeDemo from '../assets/wait-time-demo.mp4'
import ReactGA from 'react-ga4'
import { useEffect } from 'react'
import ScrollArrow from '../components/ScrollArrow'
import { motion, useScroll, useTransform } from 'framer-motion'

const Home = () => {
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname })
  }, [])

  // Add scroll progress tracking for each video panel
  const { scrollYProgress } = useScroll()

  // Create refs for each panel to track their positions
  const panel2Ref = React.useRef(null)
  const panel3Ref = React.useRef(null)
  const panel4Ref = React.useRef(null)
  const panel5Ref = React.useRef(null)

  // Create individual scroll trackers for each panel
  const { scrollYProgress: panel2Progress } = useScroll({
    target: panel2Ref,
    offset: ['start end', 'end start']
  })

  const { scrollYProgress: panel3Progress } = useScroll({
    target: panel3Ref,
    offset: ['start end', 'end start']
  })

  const { scrollYProgress: panel4Progress } = useScroll({
    target: panel4Ref,
    offset: ['start end', 'end start']
  })

  const { scrollYProgress: panel5Progress } = useScroll({
    target: panel5Ref,
    offset: ['start end', 'end start']
  })

  // Transform scroll progress into scale values
  const panel2Scale = useTransform(panel2Progress, [0, 0.5, 1], [0.8, 1, 0.8])
  const panel3Scale = useTransform(panel3Progress, [0, 0.5, 1], [0.8, 1, 0.8])
  const panel4Scale = useTransform(panel4Progress, [0, 0.5, 1], [0.8, 1, 0.8])
  const panel5Scale = useTransform(panel5Progress, [0, 0.5, 1], [0.8, 1, 0.8])

  return (
    <div className='page'>
      <div className='panel panel1' id='panel1'>
        <div className='overlay'></div>
        <Header />
        <div className='first-panel-content'>
          <motion.h1
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            viewport={{ once: false }}
          >
            Human-centric traffic lights, powered by AI
          </motion.h1>
          <motion.div
            style={{ marginTop: '40px' }}
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: false }}
          >
            <ul
              className='custom-list'
              style={{ textAlign: 'left', marginBottom: '70px' }}
            >
              {[
                'Traditional detection systems require complex stop line and advanced detector configurations with timing plans that quickly become outdated.',
                'On coordinated corridors, safety features like all-red phase extensions for slower cyclists and pedestrians cause adaptive systems to lose synchronization.',
                'Roundabout solves these problems by using AI to conduct a comprehensive traffic study four times per second, making optimal decisions based on real-time conditions.'
              ].map((text, index) => (
                <motion.li
                  key={index}
                  className={`traffic-light-${
                    index === 0 ? 'red' : index === 1 ? 'yellow' : 'green'
                  }`}
                  initial={{ opacity: 0, x: -50 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.5, delay: 0.2 * (index + 1) }}
                  viewport={{ once: false }}
                >
                  {text}
                </motion.li>
              ))}
            </ul>
          </motion.div>
        </div>
        <ScrollArrow targetPanel='panel2' />
      </div>

      <div
        className='panel panel2 shapy-background'
        id='panel2'
        ref={panel2Ref}
      >
        <div>
          <h2 className='my-2'>
            Real-time 3D perception of all road users enables us to select the
            optimal signal timing at every moment
          </h2>
        </div>
        <motion.div
          className='video-container'
          style={{ scale: panel2Scale }}
          transition={{ duration: 0.3 }}
        >
          <video
            className='demo-video'
            autoPlay
            muted
            controls
            playsInline
            preload='auto'
            loop
          >
            <source
              src={demoVideo}
              type='video/mp4'
              style={{ filter: 'brightness(0.5)' }}
            />
            Unable to play demo video
          </video>
        </motion.div>
        <ScrollArrow targetPanel='panel3' />
      </div>

      <div
        className='panel panel3 shapy-background'
        id='panel3'
        ref={panel3Ref}
      >
        <div>
          <h2 className='my-2'>
            Minimize wait times for all road users by understanding every
            vehicle's movement patterns
          </h2>
        </div>
        <motion.div
          style={{ scale: panel3Scale }}
          transition={{ duration: 0.3 }}
        >
          <video
            className='demo-video'
            autoPlay
            muted
            controls
            playsInline
            preload='auto'
            loop
          >
            <source
              src={waitTimeDemo}
              type='video/mp4'
              style={{ filter: 'brightness(0.5)' }}
            />
            Unable to play demo video
          </video>
        </motion.div>
        <ScrollArrow targetPanel='panel4' />
      </div>

      <div
        className='panel panel4 shapy-background'
        id='panel4'
        ref={panel4Ref}
      >
        <div>
          <h2 className='my-2'>
            Discourage speeding by giving red lights to speeding vehicles in
            high-injury corridors
          </h2>
        </div>
        <motion.div
          style={{ scale: panel4Scale }}
          transition={{ duration: 0.3 }}
        >
          <video
            className='demo-video'
            autoPlay
            muted
            controls
            playsInline
            preload='auto'
            loop
          >
            <source src={speedDemo} type='video/mp4' />
            Unable to play demo video
          </video>
        </motion.div>
        <ScrollArrow targetPanel='panel5' />
      </div>

      <div
        className='panel panel5 shapy-background'
        id='panel5'
        ref={panel5Ref}
      >
        <div>
          <h2 className='my-2'>
            Protect pedestrians who need extra time to cross
          </h2>
        </div>
        <motion.div
          style={{ scale: panel5Scale }}
          transition={{ duration: 0.3 }}
        >
          <video
            className='demo-video'
            autoPlay
            muted
            controls
            playsInline
            preload='auto'
            loop
          >
            <source src={pedDemo} type='video/mp4' />
            Unable to play demo video
          </video>
        </motion.div>
        <ScrollArrow targetPanel='panel6' />
      </div>

      <div className='panel panel6 bullet-point-panel-background' id='panel6'>
        <div className='overlay'></div>
        <div className='flex-content'>
          <motion.div
            style={{ maxWidth: '800px', marginBottom: '80px' }}
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: false }}
          >
            <h1 style={{ textAlign: 'left', marginBottom: '50px' }}>
              Roundabout isn't like other systems.
            </h1>
          </motion.div>
          <motion.div
            style={{
              maxWidth: '800px',
              paddingBottom: '200px'
            }}
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.3 }}
            viewport={{ once: false }}
          >
            <ul className='custom-list mt-12' style={{ textAlign: 'left' }}>
              {[
                'Prioritize buses & emergency vehicles with no additional hardware',
                'Hold all-red phases for cyclists and pedestrians caught in the intersection',
                'Adapt to nearby signals controlled by other agencies',
                'Respond automatically to traffic patterns from special events or road closures',
                'Never worry about coordinated corridors getting out of sync again',
                'Save hours of cumulative wait times for all road users by adapting to traffic conditions, intersection by intersection, in real time'
              ].map((text, index) => (
                <motion.li
                  key={index}
                  initial={{ opacity: 0, x: -50 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.5, delay: 0.2 * (index + 1) }}
                  viewport={{ once: false }}
                >
                  {text}
                </motion.li>
              ))}
            </ul>
          </motion.div>
        </div>
        <ScrollArrow targetPanel='panel7' />
      </div>

      <div
        className='panel shapy-background panel7 relative h-screen'
        id='panel7'
      >
        <motion.div
          className='flex flex-col items-center justify-center h-[calc(100%-160px)]'
          initial={{ opacity: 0, scale: 0.9 }}
          whileInView={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: false }}
        >
          <h1 className='text-center mb-8'>
            Let's talk about how we can help your city!
          </h1>
          <motion.a
            className='book-demo-btn mt-8'
            href='/contact'
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Schedule a demo
          </motion.a>
        </motion.div>
        <Footer className='absolute bottom-0 w-full' />
      </div>
    </div>
  )
}

export default Home
